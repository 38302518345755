/* About.css */

.about-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
}

.about-banner {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
}

.about-content {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-content h1 {
    font-size: 2.5rem;
    color: #0077b6;
    margin-bottom: 15px;
}

.about-content h2 {
    font-size: 1.8rem;
    color: #555;
    margin-top: 25px;
    margin-bottom: 10px;
}

.about-content p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

.about-list {
    list-style-type: disc;
    margin-left: 20px;
    margin-top: 10px;
}

.about-list li {
    font-size: 1.1rem;
    margin-bottom: 10px;
}

/* Responsive design */
@media (max-width: 768px) {
    .about-content h1 {
        font-size: 2rem;
    }

    .about-content h2 {
        font-size: 1.5rem;
    }

    .about-content p,
    .about-list li {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .about-content h1 {
        font-size: 1.8rem;
    }

    .about-content h2 {
        font-size: 1.3rem;
    }

    .about-content p,
    .about-list li {
        font-size: 0.9rem;
    }
}
