/* Container principal */
.product-display-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  /* Titre principal */
  .title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    color: #4CAF50;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  /* Message d'erreur */
  .error {
    color: #f44336;
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  /* Grille des produits */
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  /* Carte de produit */
  .product-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Conteneur de l'image */
  .product-image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-bottom: 1px solid #ddd;
  }
  
  .product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .product-card:hover .product-image {
    transform: scale(1.1);
  }
  
  /* Informations sur le produit */
  .product-info {
    padding: 15px;
    text-align: left;
  }
  
  .product-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .product-category,
  .product-description,
  .product-material {
    font-size: 1rem;
    color: #555;
    margin-bottom: 5px;
  }
  
  .product-price {
    font-size: 1.3rem;
    font-weight: bold;
    color: #4CAF50;
    margin-right: 10px;
  }
  
  /* Pied de la carte produit */
  .product-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  
  /* Bouton Acheter Maintenant */
  .add-to-cart-btn {
    background-color: #4CAF50;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-to-cart-btn:hover {
    background-color: #45a049;
  }
  
  /* Responsive : ajustement pour petits écrans */
  @media (max-width: 768px) {
    .title {
      font-size: 2rem;
    }
  
    .product-info {
      padding: 10px;
    }
  
    .add-to-cart-btn {
      padding: 8px 12px;
      font-size: 0.9rem;
    }
  }
  