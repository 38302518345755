.all-articule {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.all-articule h2 {
  text-align: center;
  color: #333;
  font-size: 2rem;
  margin-bottom: 20px;
}

.error {
  text-align: center;
  color: red;
  font-weight: bold;
}

.articles-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.article-item {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.article-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.article-item h3 {
  font-size: 1.5rem;
  color: #11998e;
  margin-bottom: 10px;
}

.article-item p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
}

.article-item img {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 15px;
  display: block;
}

.listproduct-remove-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  transition: transform 0.3s, opacity 0.3s;
}

.listproduct-remove-icon:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .all-articule {
    padding: 10px;
  }

  .article-item h3 {
    font-size: 1.2rem;
  }

  .article-item p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .article-item {
    padding: 10px;
  }

  .article-item h3 {
    font-size: 1rem;
  }

  .listproduct-remove-icon {
    width: 25px;
    height: 25px;
  }
}
