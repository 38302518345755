/* concrast.css */
.concrast-container {
    padding: 50px 20px;
    text-align: center;
    background-color: #f5f5f5; /* Fond neutre pour un bon contraste */
    color: #333;
}

.concrast-container h2 {
    font-size: 2.2em;
    font-weight: 700;
    margin-bottom: 20px;
    color: #222;
}

.intro-text {
    font-size: 1.1em;
    margin-bottom: 15px;
    color: #555;
}

.details-text {
    font-size: 1em;
    margin-bottom: 20px;
    color: #666;
}

.features-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
}

.feature-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 25px;
    width: 250px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.icon {
    font-size: 2.5em;
    margin-bottom: 15px;
    color: #ff6f61; /* Couleur pour les icônes */
}

.feature-card h3 {
    font-size: 1.3em;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
}

.feature-card p {
    font-size: 0.9em;
    color: #777;
}
