/* Style global du composant */
.user-management {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;
}

.user-management h2 {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

/* Style de la liste d'utilisateurs */
.user-list {
    list-style: none;
    padding: 0;
}

.user-list li {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #ddd;
}

.user-list p {
    margin: 5px 0;
    color: #555;
}

/* Style du bouton de promotion */
.promote-button {
    align-self: flex-start;
    padding: 8px 12px;
    background-color: #38ef7d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
}

.promote-button:hover {
    background-color: #2bb363;
}

/* Style pour le texte du rôle */
.user-role {
    font-weight: bold;
    color: #11998e;
}

.admin-role {
    color: #11998e;
}
