.admin-contact-container {
    padding: 20px;
    background-color: #f9f9f9;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.message-list {
    list-style-type: none;
    padding: 0;
}

.message-item {
    background-color: white;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
}

.message-item p {
    margin: 5px 0;
}

.error-message {
    color: red;
    text-align: center;
}
