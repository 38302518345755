.admin-articule {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: linear-gradient(180deg, #11998e 0%, #38ef7d 50%, #11998e 100%);
    color: #333;
    font-family: Arial, sans-serif;
  }
  
  .admin-articule h2 {
    text-align: center;
    color: #fff;
  }
  
  .admin-articule form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .admin-articule label {
    display: flex;
    flex-direction: column;
    color: #fff;
    font-weight: bold;
  }
  
  .admin-articule input[type="text"],
  .admin-articule textarea,
  .admin-articule input[type="file"] {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 5px;
    font-size: 16px;
  }
  
  .admin-articule input[type="text"]:focus,
  .admin-articule textarea:focus,
  .admin-articule input[type="file"]:focus {
    outline: none;
    border-color: #38ef7d;
    box-shadow: 0 0 5px rgba(56, 239, 125, 0.5);
  }
  
  .admin-articule button[type="submit"] {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #11998e;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .admin-articule button[type="submit"]:hover {
    background-color: #38ef7d;
  }
  
  .admin-articule p {
    text-align: center;
    font-weight: bold;
    color: #fff;
  }
  