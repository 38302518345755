.commande {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    max-width: 700px;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.commande h2 {
    text-align: center;
    color: #333;
}

.commande-list {
    list-style: none;
    padding: 0;
}

.commande-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.commande-item h3 {
    margin: 5px 0;
    color: #11998e;
}

.commande-item p {
    margin: 5px 0;
    color: #555;
}

.commande-image {
    max-width: 100%;
    border-radius: 5px;
    margin: 10px 0;
}

.promote-button {
    padding: 8px 12px;
    background-color: #38ef7d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
}

.promote-button:hover {
    background-color: #2bb363;
}
