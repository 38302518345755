/* Conteneur principal des produits */
.product-container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-content: center;
    align-items: stretch;
    padding: 20px;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  /* Carte individuelle du produit */
  .product-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    padding: 15px;
  }
  
  /* Effet au survol de la carte */
  .product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  /* Image du produit */
  .product-image {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
    object-fit: cover;
    max-height: 200px;
  }
  
  /* Titre du produit */
  .product-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Description du produit */
  .product-description {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .product-card {
      padding: 10px;
    }
    .product-title {
      font-size: 1rem;
    }
    .product-description {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .product-container {
      grid-template-columns: 1fr;
    }
    .product-card {
      margin: 0 auto;
    }
  }
  