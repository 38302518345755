.add-product-admin {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
    background-color: #f0f4f8;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .add-product-admin h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .add-product-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-label {
    font-weight: bold;
    color: #333;
  }
  
  .form-input,
  .form-textarea,
  .form-select,
  .form-file,
  .form-checkbox {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 6px;
  }
  
  .form-textarea {
    resize: vertical;
    height: 150px;
  }
  
  .form-checkbox {
    width: auto;
  }
  
  .submit-btn {
    background-color: #4CAF50;
    color: white;
    padding: 12px 18px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
  }
  
  .submit-btn:hover {
    background-color: #45a049;
  }
  
  .response-message {
    text-align: center;
    font-size: 18px;
    color: #4CAF50;
    font-weight: bold;
    margin-top: 20px;
  }
  