/* help.css */

.help-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
}

h1 {
    font-size: 2.5rem;
    text-align: center;
    color: #0077b6;
}

.contact-info {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-info h2 {
    font-size: 1.8rem;
    color: #555;
    margin-bottom: 10px;
}

.contact-info p {
    font-size: 1.1rem;
    margin: 5px 0;
}

.contact-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
    font-size: 1.8rem;
    color: #555;
    margin-bottom: 15px;
}

.contact-form label {
    display: block;
    margin-bottom: 10px;
    font-size: 1.1rem;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.contact-form textarea {
    resize: vertical;
    min-height: 100px;
}

.contact-form button {
    width: 100%;
    padding: 10px;
    font-size: 1.1rem;
    color: #fff;
    background-color: #0077b6;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: #005f8a;
}

.status-message {
    text-align: center;
    margin-top: 10px;
    color: green;
}

/* Responsive design */
@media (max-width: 600px) {
    h1 {
        font-size: 2rem;
    }

    .contact-info h2,
    .contact-form h2 {
        font-size: 1.5rem;
    }

    .contact-info p,
    .contact-form label {
        font-size: 1rem;
    }
}
