/* Conteneur principal de la page admin */
.admin-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  /* Barre de navigation */
  nav {
    display: flex;
    flex-wrap: wrap;
    background-color: #2c3e50;
    padding: 10px;
    justify-content: center;
    gap: 10px;
  }
  
  nav button {
    background-color: #34495e;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  nav button.active {
    background-color: #e74c3c;
    transform: scale(1.05);
  }
  
  nav button:hover {
    background-color: #e67e22;
  }
  
  /* Contenu principal */
  .admin-content {
    padding: 20px;
    background-color: #f8f9fa;
    flex: 1;
    overflow-y: auto;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  
  /* Responsive design */
  
  /* Petits écrans (max-width: 768px) */
  @media (max-width: 768px) {
    nav {
      flex-direction: column;
      align-items: center;
    }
  
    nav button {
      width: 90%;
      font-size: 1rem;
    }
  
    .admin-content {
      padding: 15px;
      font-size: 0.9rem;
    }
  }
  
  /* Très petits écrans (max-width: 480px) */
  @media (max-width: 480px) {
    nav button {
      width: 100%;
      font-size: 0.9rem;
      padding: 8px 10px;
    }
  
    .admin-content {
      padding: 10px;
      font-size: 0.8rem;
    }
  }
  