.all-product-admin {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
    background-color: #f0f4f8;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .all-product-admin h2 {
    text-align: center;
    font-size: 24px;
    color: #333;
  }
  
  .product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
  }
  
  .product-item {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 200px;
    text-align: center;
  }
  
  .product-item h3 {
    font-size: 18px;
    color: #333;
  }
  
  .product-item p {
    font-size: 14px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .product-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .product-actions {
    margin-top: 10px;
  }
  
  .remove-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: 10px;
  }
  
  .error {
    color: red;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
  }
  