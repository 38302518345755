/* Styles de base pour le conteneur principal */
.contact-container {
    display: flex;
    justify-content: space-between;
    padding: 40px;
    background-color: #F5F0E6;
    border-radius: 8px;
    max-width: 1200px;
    margin: auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    gap: 20px;
    margin-top: 50px;
}

/* Styles pour la section d'information de contact */
.contact-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
}

.contact-info div {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-info h4 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.contact-info p {
    text-align: center;
    line-height: 1.6;
    color: #555;
}

/* Styles pour la section du formulaire de contact */
.right-side {
    flex: 1;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.contact-form input, 
.contact-form textarea {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    font-size: 1rem;
}

.contact-form button {
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-form button:hover {
    background-color: #0056b3;
}

.contact-form .error-message {
    color: red;
    font-size: 0.9rem;
    text-align: center;
}

/* Styles pour le recaptcha */
.contact-form .g-recaptcha {
    margin-top: 20px;
}

/* Media queries pour la responsivité */
@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        padding: 20px;
    }
}
