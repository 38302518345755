.info {
    padding: 20px;
    background-color: #f0f4f8;
    border-radius: 8px;
    margin-bottom: 20px;
}

.info h2, .info h3 {
    color: #333;
}

.info p {
    color: #555;
    line-height: 1.6;
}
